<template>
  <div class="privacy_policy_section">
    <section>
      <article style="
          padding-top: 50px;
          border: 1px solid transparent;
            background-color: #F0F9EB;
          ">
        <div
          class="container"
          style="margin: 50px auto; text-align: left"
        >
          <div class="terms_introduction">
            <p class="locus_word_style">
              <small>ACCESS UGANDA SAFARIS</small>
            </p>
            <h1 style="font-weight: 600; font-size: 2em">
              Privacy Policy.
            </h1>
            <p style="font-weight: 300">
              We highly value your trust in us and will duly and diligently safeguard and protect the
              privacy and confidentiality of your personal information.
            </p>
          </div>

          <section>
            <article>
              <el-collapse
                v-model="activeNames"
                @change="handleChange"
              >
                <el-collapse-item
                  title="We don't spam"
                  name="1"
                >
                  <div class="pt-3">
                    <p>We won't email you unless you have expressly indicated you want us to. We will only email you in one of the following cases:</p>
                  </div>
                  <div>
                    <ul>
                      <li>You activate the optional feature to receive a periodical newsletter by email. Please note: you can always deactivate this feature by unsubscribing. Each newsletter contains an unsubscribe link at the bottom of the email.</li>
                      <li>You use one of our contact forms because you have a question or want to provide feedback to us. In those cases, you may receive a copy of your message and our reply to your request.</li>
                      <li>You use one of our contact forms to request a quote for a tour. We will email you in reply to your request.</li>
                    </ul>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  title="What information we collect and for what purpose"
                  name="2"
                >
                  <div class="pt-3">
                    <h2><strong>Personal information</strong></h2>
                    <p>
                      We keep the amount of personal information we collect to an absolute minimum and only
                      collect it if strictly necessary or with your consent.
                      You are not obliged to provide your personal information,
                      but this may lead to us not being able to provide you with our services.
                    </p>
                    <p>We will not process or store your personal information for any longer than is necessary for the purposes which we collected or processed this information, unless a longer storage period is required by any applicable law or regulation. We process the following information:</p>
                    <div>
                      <ul>
                        <li>
                          <h3>User reviews</h3>
                          <p>Upon submitting a user review, we will ask you to provide a screen name (which can be your full name or your first name only) and you will be given the option to provide more info about yourself such as your gender, age, safari experience level, country of residence and photos of your safari. The IP address from where you uploaded the review will also be stored in our databases for spam prevention reasons. We also ask for your email address so that we can contact you in case there is a problem with your review or if other website users have a question about your review. All this info will be publicly visible, except for your IP address and email.</p>
                          <p>When other users have a question about your review, they can fill out a form that does not reveal your email address and we will forward their question to you by email. In addition, every question that is forwarded to you by email contains an unsubscribe option in case you no longer want other users to be able to ask questions about your review.</p>
                          <p>Email notifications regarding your review and/or review invite will be sent to you, us and the safari company that is being reviewed. These emails contain your name and email address.</p>
                          <p></p>
                        </li>
                        <li>
                          <h3>Quote requests</h3>
                          <p>You could decide to use an email form on our website to contact a safari company and request a quote for a tour. These quote requests contain your name, email address, phone number and IP address. Your IP address is stored for spam prevention reasons and will not be shared with the safari company. The quote requests will be emailed directly to the safari company with a copy to you and us. The copy to us will also be stored in our database.</p>
                          <small>After two years all personal information associated with a quote request will be anonymized.</small>
                        </li>
                        <li>
                          <h3>Email forms to contact us, Access Uganda Safaris</h3>
                          <p>You could decide to contact us by email. These emails will be stored in our email inboxes. We can use the email address or other contact information to get back to you in response to your request.</p>
                          <p>If you use our email forms to report a technical problem with our website we will also collect your IP address for support purposes. The IP address is used to locate your browse session in our log files, which enables us to further investigate the reported problem.</p>
                          <small>After two years all personal information associated with a quote request will be anonymized.</small>
                        </li>
                        <li>
                          <h3>Email Newsletters</h3>
                          <p>You may decide to subscribe to one or several of our newsletters in order to periodically receive safari-related news, tips, articles and/or offers by email. When subscribing, your name and email address will be shared with us. Every newsletter that you receive contains an unsubscribe option in case you no longer want to receive these emails.</p>
                          <p>If you unsubscribe, you will stop receiving newsletters instantly and your name and email address will be removed from our systems within 90 days.</p>

                        </li>
                        <li>
                          <h3>Computer information</h3>
                          <p>If you use one of the email forms to report a technical problem we will collect non-personally identifiable computer information, such as: the type of browser you are using (e.g. Firefox, Internet Explorer, Google Chrome), the type of operating system you are using (e.g. Windows, Mac OS), the screen or window resolution of your monitor and device type (e.g. PC, iPhone, iPad). We collect this information since it helps us greatly find the problem and it has little or no impact on your privacy.</p>

                        </li>
                        <li>
                          <h3>User Generated Content</h3>
                          <p>User generated content ("user content") is information provided by our users with an intent to be used on our website (e.g. a user review or post on our boards). You, as our user, are responsible for all user content that you submit, post, or otherwise make available to or through our website. We don't claim ownership of user content, but by submitting, posting or otherwise making available to or through our website, you automatically grant us the right to use your user content in any way we desire. This includes the non-exclusive, perpetual, transferable, irrevocable right, with the right of sublicensing and without any royalty or compensation in return, to use, reproduce, adjust, translate, distribute, publish, create derivate works, to disclose and duplicate the content in all now known media and future media. You agree that we can decide if and how your user content is credited and you also agree the user content provided may be indexed by search engines such as Google. You also grant us, and any third party appointed by us, the right to take (legal) actions as it deems necessary for the protection of the (copy)rights of your user content, including, but not by way of limitation, to take (legal) action in the name and on behalf of you.</p>
                        </li>
                        <li>
                          <h3>About children under the age of 16</h3>
                          <p>Our website is not intended for children under the age of 16. We don't knowingly collect personal information from visitors in this age group. If we learn that we have collected personal information from a child under the age of 16, we will delete that information as quickly as possible. If you believe that we might have any information from a child under age 16, please contact us by sending an email to privacy@accessugandasafaris.com.</p>
                        </li>
                        <li>
                          <h3></h3>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  title="Security measures"
                  name="4"
                >
                  <div class="pt-3">
                    <p>
                      We take strict security measures to protect personal information from loss,
                      misuse, unauthorized access, disclosure, alteration or destruction.
                      These measures include limiting the number of people who have physical access to our
                      database servers as well as electronic security systems, data encryption and password
                      protections, which guard against unauthorized access.
                    </p>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  title="Control over your data"
                  name="5"
                >
                  <div class="pt-3">
                    <p>You are entitled to object to us processing your personal data. You may also access your information, ask us for an overview of your information or ask for a copy. You may also request us to correct or delete certain data, restrict processing of your data, or ask us to transfer some of this information to you or other organizations. In some cases you may object to the processing of your data and, where we have asked for your consent to process personal data, you can withdraw this consent at any time. If the processing is solely based on your consent, we will not process your personal information any longer. Where we process your data for our legitimate interests, you can contact us if you want more information about these.</p>
                    <p>There are some exceptions to these rights. For example, it will not be possible for us to delete your data if we are required by law to keep it or if we hold it in connection with a contract with you. Similarly, access to your data may be refused if making the information available would reveal personal information about another person or if we are legally prevented from disclosing such information. We will always inform you if we will not comply with your request for any reason whatsoever.</p>
                    <p>If you wish to exercise any of these rights, please contact us using the details below.</p>
                    <p>We will provide you with information on action taken on your request within one month after receiving this request. Depending on the complexity and number of requests we may extend this period by two further months, in which case we will also inform you.</p>
                  </div>
                </el-collapse-item>
                <el-collapse-item
                  title="Contacting us"
                  name="6"
                >
                  <div class="pt-3">
                    <p>ACCESS UGANDA TOURS Ltd is the controller of your personal information. Questions, comments, requests or complaints concerning this privacy notice and the way we process your personal information are welcomed and can be addressed to us at privacy@accessugandasafaris.com.</p>
                    <p>If you have a complaint about the way we handle your personal data, you also have the right to address this with the data protection authority of the country in which you live or work, or the country in which we are located.</p>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </article>
          </section>

        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ["1"],
    };
  },

  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.privacy_policy_section {
  background-color: #f0f9eb;
}

.privacy_policy_section h2 {
  font-size: 1.5em;
}

.privacy_policy_section h3 {
  font-size: 1.3em;
  font-weight: 600;
}
.locus_word_style {
  font-size: 0.5em;
  font-weight: 300;
  word-spacing: 8px;
  letter-spacing: 5px;
}
.terms_introduction {
  width: 60%;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .terms_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .terms_introduction {
    width: 100%;
  }

  .privacy_policy_section h2 {
    font-size: 1.2em;
  }

  .privacy_policy_section h3 {
    font-size: 1em;
    font-weight: 600;
  }
}
</style>

<style>
.privacy_policy_section .el-collapse-item__content {
  background-color: #f0f9eb !important;
}
.privacy_policy_section .el-collapse-item {
  background-color: transparent !important;
}
.privacy_policy_section .el-collapse-item__header {
  background-color: #f0f9eb !important;
}
.privacy_policy_section .el-collapse-item__body {
  background-color: #f0f9eb !important;
}
.privacy_policy_section .el-collapse-item__header {
  padding: 15px 0px;
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  border-bottom: 1px solid #93cb75 !important;
  font-size: 1.2em;
  font-weight: 600;
  transition: border-bottom-color 0.3s;
  outline: 0;
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .privacy_policy_section .el-collapse-item__header {
    padding: 15px 0px;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    font-weight: 600;
  }
}
</style>
